import Crypto from 'crypto-js';

let code = null;
let authKey = null;
let userRole = null;
let userRight = null;
let rightAdmin = false;
let showDemoData = false;
let projectRoleID = null;
let rightResponder = false;
let rightResearcher = false;
let showDemoDashBoard = false;

/**
 * A rendszeren belül egy üzenet küldésének általános használata
 *
 * @param {object} self
 * @param {string} textMessage
 * @param {string} color
 * @param {int} dismissSecs
 */
let setInfoBar = (self, textMessage, color, dismissSecs = 3, loading = false) => {
    const data = {
        show: true,
        text: textMessage,
        countDown: dismissSecs,
        colorAlert: color,
        loading: loading,
    }
    self.$eventBus.$emit( 'eventSetInfoBarDatas', data );
}

// SonarLint suppress: S2301 - A logikát vezérlő flag alkalmazása ilyen esetben elfogadható
let getStorage = (key, secured = false) => {
    if (!secured) return window.localStorage.getItem(key);
	else {
		const value = window.localStorage.getItem(key);
		return value ? Crypto.AES.decrypt(value, code).toString(Crypto.enc.Utf8) : null;
	}
}

let setRightProp = (right) => {
	switch (right) {
		case 'admin':
			rightAdmin = true;
			rightResearcher = rightResponder = false;
		break;
		case 'researcher':
			rightResearcher = true;
			rightAdmin = rightResponder = false;
		break;
		case 'user':
			rightResponder = true;
			rightResearcher = rightAdmin = false;
		break;
		default:
		break;
	}
}

// SonarLint suppress: S2301 - A logikát vezérlő flag alkalmazása ilyen esetben elfogadható
const setStorage = (key, value, secured = false) => {
    if (!secured) window.localStorage.setItem(key, value);
	else {
		const encrypted = Crypto.AES.encrypt(value, code).toString();
		window.localStorage.setItem(key, encrypted);
	}
}

const removeFromStorage = (key) => {
    window.localStorage.removeItem(key);
}

/**
 * A trix editor automatikus frissítése
 *
 * @param {string} elem
 * @param {object} langs
 * @param {string} htmlContent
 */
const refreshTrixEditor = (elem, langs, htmlContent) => {
	//az alábbi sorok a tabváltás miatt kellenek, h a trix biztosan frissüljön
	const toolBars = document.querySelectorAll('trix-toolbar');
	const elemNum = langs.length;
	if (toolBars) {
		//ezt azért kell így, mert ahány nyelvi elem van, annyi tab, s rajta pedig ennyi db editor lesz
		const removeToolbars = Array.from(toolBars).slice(-elemNum);
		removeToolbars.forEach(toolbar => toolbar.remove());
	}
	//Mivel a trix nem frissíti magát auto a v-model alapján, így kell ez a kis megoldás
	const trixEditor = document.querySelector(elem);
	if (trixEditor) trixEditor.editor.loadHTML(htmlContent);
}

export const getAuth = () => {
	return getStorage(authKey, true) ? JSON.parse(getStorage(authKey, true)) : null;
}

export const setRight = (right) => {
	userRight = right;
	setRightProp(right);
}

export const setRoles = (roles) => {
	userRole = roles;
}

export const setProjectRoleID = (roleID) => {
	projectRoleID = roleID;
}

export const logoutFromORT = (self) => {
	let that = self;
	self.$api.post('logout')
	.then(() => {
		clearInterval( that.$store.state.loggedInterval );
		that.$store.commit('set', ['loggedInterval', null]);
		that.$store.commit('set', ['isAuthenticated', false]);
		that.$removeFromStorage(self.$authKey);
		that.$router.push({ path: '/login' });
	}).catch((error) => {
		console.error(error);
	});
}

export const getDemoDashboard = () => {
	return showDemoDashBoard;
}

export const validatingEmail = (emailAdd) => {
	const emailLocalPart = '[^<>()[\\].,;:\\s@"]+(\\.[^<>()[\\].,;:\\s@"]+)*|"(.+)"';
	const ipAddressPart = '\\[[0-9]{1,3}(\\.[0-9]{1,3}){3}\\]';
	const domainPart = '[a-zA-Z\\-0-9]+(\\.[a-zA-Z\\-0-9]+)*\\.[a-zA-Z]{2,}';
	const re = new RegExp(`^(${emailLocalPart})@(${ipAddressPart}|${domainPart})$`);
    return re.test(String(emailAdd).toLowerCase());
}

export const validatingPassword = ( pwd ) => {
    const pwdTemp = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;
    return pwdTemp.test( pwd );
}

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const defNum = this.$defaultNumber;
				code = String.fromCharCode(
					(defNum+15), (defNum+9), (defNum+40), (defNum+53), (defNum-30),
					(defNum+51), (defNum-10), (defNum-11), (defNum+21), (defNum+59)
				);
				const agent = navigator.userAgent;
				code = `${code}|${agent}`;
				authKey = this.$authKey;
				Vue.prototype.$getAuth = getAuth;
				Vue.prototype.$setRight = setRight;
				Vue.prototype.$setRoles = setRoles;
				Vue.prototype.$userRole = userRole;
				Vue.prototype.$userRight = userRight;
				Vue.prototype.$rightAdmin = rightAdmin;
				Vue.prototype.$showDemoData = showDemoData;
				Vue.prototype.$projectRoleID = projectRoleID;
				Vue.prototype.$rightResponder = rightResponder;
				Vue.prototype.$rightResearcher = rightResearcher;
				Vue.prototype.$validatingEmail = validatingEmail;
				Vue.prototype.$validatingPassword = validatingPassword;
				Vue.prototype.$setProjectRoleID = setProjectRoleID;
				Vue.prototype.$getDemoDashboard = getDemoDashboard;
				Vue.prototype.$getStorage = getStorage;
				Vue.prototype.$setStorage = setStorage;
				Vue.prototype.$setInfoBar = setInfoBar;
				Vue.prototype.$logoutFromORT = logoutFromORT;
				Vue.prototype.$removeFromStorage = removeFromStorage;
				Vue.prototype.$refreshTrixEditor = refreshTrixEditor;
			}
		});
	}
}
