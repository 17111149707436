import axios from 'axios';
import router from '../router/index';
import { getAuth } from './ortutils';
import LoginModal from '@/components/loginmodal/LoginModal';

let modalShow = false;

// létrehozzuk az instance-ot, amit az összes komponensben el lehet érni
const createApiInstance = (axiosInstance, Vue) => {
	axiosInstance.interceptors.request.use((config) => {
		const auth = getAuth();
		if (auth) {
			config.headers['Authorization'] = `Bearer ${auth.token}`;
		}
		return config;
	});
	if (typeof axiosInstance === 'object' && axiosInstance.interceptors.response !== null) {
		instance401Checker(axiosInstance, Vue);
	}
	else {
		global401checker();
	}
	Vue.prototype.$api = axiosInstance;
}

// az axiosInstance-re való hibakezelés
const instance401Checker = (axiosInstance, Vue) => {
	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			// Ha a válaszkód 401, az axios interceptora közbelép, de csak akkor
			if (error.response && error.response.status === 401) {
				try {
					if (router.currentRoute.path !== '/login' && !modalShow) {
						modalShow = true;
						await new Promise((resolve) => {
							/**
							 * Csak akkor jelenítjük meg a modális komponenst, hogyha kevesebb
							 * mint 2 órája (vagy egyenlő) volt bent a user a rendszerben.
							 * Ha több idő telt el, akkor automatikusan átirányítjuk a login oldalra
							 */
							const auth = getAuth();
							const currentTimestamp = Math.floor(Date.now() / 1000);
							// A két időbélyeg közötti különbség számolása másodpercben
							const timeDifference = auth.sessionExpire - auth.sessionTimeStamp;
							// Az időkorlát beállítása (például 50 perc másodpercben)
							const timeLimitInSeconds = 3000;
							if (currentTimestamp < auth.sessionExpire && timeDifference <= timeLimitInSeconds) {
								// Megjelenítjük a LoginModal komponenst, s átadjuk neki a routert
								const loginComponent = new Vue({
									render: (h) => h(LoginModal, {props: {router}}),
								}).$mount();
								document.body.appendChild(loginComponent.$el);
							}
							else router.push('/login');
							resolve();
						});
					}
				}
				catch (error) {
					console.error('A komponens aktiválása a következő hibába ütközött: ', error);
				}
			}
			return Promise.reject(error);
		}
	);
}

// Globális hibakezelés Axiosban
const global401checker = () => {
	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			// Ha a válaszkód 401, az axios interceptora közbelép, de csak akkor
			if (error.response && error.response.status === 401) {
				try {
					if (router.currentRoute.path !== '/login') {
						await router.push('/login');
					}
				}
				catch (error) {
					console.error('A navigáció a következő hibába ütközött: ', error);
				}
			}
			return Promise.reject(error);
		}
	);
}

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
				const axiosInstance = axios.create({
					baseURL: apiUrl + '/api/'
				});
				createApiInstance(axiosInstance, Vue);
			}
		});
		// Az általánosított fileUpload függvény
		Vue.prototype.$fileUpload = (attachment, url, data = null) => {
			if (attachment.file || attachment) {
				const file = attachment.file ? attachment.file : attachment;
				const auth = getAuth();
				let formData = new FormData();
				formData.append('file', file);
				if (data) {
					if (data.pid) formData.append('pid', data.pid);
					if (data.mid) formData.append('mid', data.mid);
				}
				const config = {
					method: 'post',
					url: url,
					headers: {
						'Authorization': `Bearer ${auth.token}`,
						'Content-Type': 'multipart/form-data'
					},
					data: formData
				};
				return axios(config)
			}
		}
	}
}
